<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Anti-fungal Treatment</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Anti-fungal Treatment
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <img
                    class="service-img"
                    src="assets/img/service/gel.png"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Anti-fungal Treatment for Wood Packing Materials</h3>
                    <p>
                        Ensure durable wooden packing materials with our
                        effective anti-fungal treatment for product showcasing
                        abroad
                    </p>
                    <h6>Anti-Fungal Treatment:</h6>
                    <p>
                        Prior to shipment, we ensure the effectiveness of our
                        anti-fungal treatment for wooden packing materials. This
                        crucial step extends the lifespan of the treated
                        materials, providing added value and reliability.
                    </p>
                    <h6>Longevity Assurance:</h6>
                    <p>
                        Our treatment not only safeguards against fungi but also
                        ensures that the wooden packing materials remain robust
                        and long-lasting. Customers can trust these materials to
                        protect and showcase their products in the importing
                        country.
                    </p>
                    <h6>Quality Protection:</h6>
                    <p>
                        We prioritize quality in our anti-fungal treatment,
                        offering peace of mind to customers who rely on these
                        materials to preserve their goods during transit and
                        exhibition.
                    </p>
                    <h6>Value Addition:</h6>
                    <p>
                        With our comprehensive approach, we add value to wooden
                        packing materials, ensuring they serve as reliable
                        assets for customers, promoting the safe transport and
                        presentation of their products in the importing country.
                    </p>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
