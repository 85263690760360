import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-service-details",
    templateUrl: "./food-processing.component.html",
    styleUrls: ["./food-processing.component.scss"],
})
export class FoodProcessingComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
