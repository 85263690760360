<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Fumigation</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Fumigation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="service-details-post">
                    <div class="service-detail-card-div">
                        <div class="w-50">
                            <h2>Fumigation</h2>
                            <p>
                                Fumigation refers to the techniques deployed to
                                eliminate the pests within a specific container
                                or cargo with the help of effective fumigants.
                                Once this process has been conducted, the
                                fumigation company will issue a certificate that
                                will indicate to the customs clearance officers
                                that the cargo fumigation has been conducted in
                                line with leading global practices. Carriers and
                                shippers carry these certifications to prove
                                cargo has undergone fumigation. These
                                certifications contain details about the cargo
                                fumigation processes, purpose, temperature
                                range, and the fumigants utilized in the
                                process. Cargo and container fumigation is
                                mandatory for international shipping and transit
                                under the banner of the IMO International
                                Maritime Dangerous Goods Code, and it is also
                                required by national governments.
                            </p>
                        </div>
                        <img
                            class="service-img w-50"
                            src="assets/img/service/fumigation.jpg"
                            alt="service"
                        />
                    </div>
                </div>
                <div class="service-details-post">
                    <h4>Types of fumigation:-</h4>
                    <br />
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Container Fumigation-</h3>
                                <p>
                                    The container fumigation procedure aims to
                                    prevent the entry of exotic quarantine pests
                                    into the cargo’s country of destination. In
                                    this procedure, special products are used to
                                    eradicate or control the international
                                    spread of pests that already exist in the
                                    cargo’s origin, as well as those originating
                                    from other countries
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/container fumigation.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Sea Ship Fumigation-</h3>
                                <p>
                                    Sea ship fumigation, also known as vessel
                                    fumigation, is a specialized process used to
                                    eliminate or control pests and pathogens on
                                    ships, cargo containers, or their contents.
                                    This procedure is crucial to ensure the
                                    safety of goods in transit, prevent the
                                    spread of harmful pests and diseases, and
                                    comply with international regulations and
                                    quarantine requirements. Sea ship fumigation
                                    is primarily performed to control and
                                    eradicate pests, such as insects, rodents,
                                    and microorganisms, that may be present on
                                    board a vessel or in its cargo. Common
                                    fumigants include methyl bromide, mostly we
                                    use Methyl bromide and in some cases we
                                    apply phosphine gas.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/shipfumigation.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Methyl Bromide Treatment-</h3>
                                <p>
                                    Fumigation of Wooden pallets is done in two
                                    methods, namely methyl bromide and heat
                                    treatment. Methyl bromide treatment: is the
                                    process of stacking pallets in a sealed
                                    chamber, then sprayed with disinfection
                                    chemical to kill of any bacteria from the
                                    wood.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/methyl.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Heat Treatment-</h3>
                                <p>
                                    Often referred to as heat treatment
                                    fumigation, is a method used to sterilize
                                    and treat wooden pallets to eliminate pests,
                                    insects, and pathogens that may be present
                                    in or on the wood. This process is widely
                                    used in the shipping and logistics industry
                                    to ensure that wooden pallets meet
                                    international phytosanitary standards and
                                    can be safely used for transporting goods
                                    across borders.
                                </p>
                                <p>
                                    It helps wooden pallets comply with
                                    international regulations and standards,
                                    such as the International Standards for
                                    Phytosanitary Measures (ISPM 15), which
                                    require the treatment of wooden packaging
                                    materials for international trade. The
                                    pallets are placed in a heat treatment
                                    chamber or kiln, where they are exposed to
                                    high temperatures for a specific duration.
                                    The treatment typically involves heating the
                                    pallets to a core temperature of at least
                                    56°C (132.8°F) for a specific period, such
                                    as 30 minutes.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/heat.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Air Cargo Fumigation-</h3>
                                <p>
                                    Air cargo fumigation is a specialized
                                    process used to control or eliminate pests
                                    and pathogens in goods and cargo being
                                    transported by air. It is essential to
                                    ensure the safety of cargo, protect public
                                    health, prevent the spread of pests and
                                    diseases across international borders, and
                                    comply with quarantine and phytosanitary
                                    regulations.
                                </p>
                                <p>
                                    Cargo: Air cargo fumigation can be applied
                                    to a wide range of goods and commodities,
                                    including perishable agricultural products,
                                    textiles, electronics, machinery, and more.
                                </p>
                                <p>
                                    Containers: Cargo containers that are loaded
                                    onto airplanes can also be fumigated to
                                    prevent pest infestations during air
                                    transport.
                                </p>
                                <p>
                                    Depending on the type of pests, cargo, and
                                    regulations, an appropriate fumigant is
                                    chosen. Common fumigants include methyl
                                    bromide, phosphine gas, or other approved
                                    chemicals.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/aircargo.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Safety and Compliance:</h3>
                                <p>
                                    Air cargo fumigation is conducted by trained
                                    and certified professionals who follow
                                    strict safety guidelines and regulations to
                                    protect both human health and the
                                    environment. Compliance with international
                                    regulations, such as the International Air
                                    Transport Association (IATA) regulations,
                                    the International Plant Protection
                                    Convention (IPPC), and local quarantine
                                    laws, is essential.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/safety.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
