<div class="home-style-three home-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-7 offset-lg-1">
                        <div class="home-text-three">
                            <h1>
                                Your Trusted Path to
                                <span> Pest-Free Living.</span>
                            </h1>
                            <p>
                                With a commitment to innovation and eco-friendly
                                practices, we've earned the trust of clients
                                nationwide.
                            </p>

                            <div class="theme-button">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://api.whatsapp.com/send?phone=919728882010"
                                    class="default-btn active-btn"
                                    >Get A Quote</a
                                >
                                <a routerLink="/service" class="default-btn"
                                    >Services</a
                                >
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-5 p-0">
                        <div class="home-image">
                            <img
                                src="assets/img/service/fumigation.jpg"
                                alt="home image"
                            />
                        </div>
                    </div>
                </div>

                <div class="home-three-shapes">
                    <img src="assets/img/process-pattern-3.png" alt="shape" />
                    <img src="assets/img/process-pattern-4.png" alt="shape" />
                    <img src="assets/img/process-pattern-5.png" alt="shape" />
                    <img src="assets/img/process-pattern-6.png" alt="shape" />
                    <img src="assets/img/process-pattern-7.png" alt="shape" />
                    <img src="assets/img/process-pattern-6.png" alt="shape" />
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="about image" />

                    <div class="counter-section">
                        <div class="counter-area">
                            <div class="row">
                                <div
                                    class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1"
                                >
                                    <div class="counter-text">
                                        <h2><span class="counter">1</span>K</h2>
                                        <p>Job Done</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                                    <div class="counter-text">
                                        <h2>
                                            <span class="counter">989</span>
                                        </h2>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>

                                <div
                                    class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1"
                                >
                                    <div class="counter-text">
                                        <h2><span class="counter">45</span></h2>
                                        <p>Experts</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                                    <div class="counter-text">
                                        <h2><span class="counter">11</span></h2>
                                        <p>Years of Experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pl-35">
                <div class="section-head">
                    <span>About Us</span>
                    <h2>We Have Been Doing Projects Since 2014</h2>
                    <p>
                        Evolving from a humble start, we're now India's foremost
                        pest control innovators. Our arsenal includes herbal
                        pesticides, cutting-edge rodent repellents, and holistic
                        integrated pest management – going beyond traditional
                        chemicals.
                    </p>
                    <p>
                        Our relentless pursuit of excellence has won us the
                        trust of top clients nationwide. Our company, led by a
                        dedicated team of experts in entomology, chemistry, and
                        bio-sciences, operates under the guidance of the
                        National Plant Protection Organization (NPPO), Ministry
                        of Agriculture, Government of India. Our accredited
                        technical managers, along with over 300 trained
                        operators, ensure compliance with stringent
                        phytosanitary standards for fumigation and pest control.
                    </p>
                </div>
                <div class="about-text">
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i> 100% Secured
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> 24/7 Services
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Well
                            Disciplined
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i> Online Payment
                        </li>
                    </ul>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=919728882010"
                            class="default-btn active-btn"
                            >Booking Now</a
                        >
                        <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="service-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <span>Services</span>
            <h2>
                We provide a wide range of services with 100% customer
                satisfaction
            </h2>
            <p>House Hold Pest Management for flying & crawling insects.</p>
        </div>

        <div class="service-slider owl-carousel owl-theme">
            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Spray Treatment</h3>
                <p>
                    Precise control through visual inspection and targeted
                    residual spraying.
                </p>

                <div class="theme-button">
                    <a routerLink="/service/spray-treatment" class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>Gel Treatment</h3>
                <p>
                    Highly effective, eco-friendly cockroach control with
                    long-lasting results, odorless and safe for sensitive areas.
                </p>

                <div class="theme-button">
                    <a routerLink="/service/gel-treatment" class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Fumigation</h3>
                <p>
                    Fumigation is the use of lethal gasses to eliminate pests,
                    crucial for safeguarding international cargo.
                </p>

                <div class="theme-button">
                    <a routerLink="/service/fumigation" class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Lashing and Packing</h3>
                <p>
                    Ensuring that cargo is properly secured and meets safety
                    regulations.
                </p>

                <div class="theme-button">
                    <a routerLink="/service/lashing-packing" class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Methyl Bromide Fumigation</h3>
                <p>
                    Essential for pest control in export cargo, but requires
                    post-fumigation care.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/fumigation/methyl-bromide-fumigation"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>Phosphine Fumigation</h3>
                <p>
                    Aluminum Phosphide: Globally trusted for commodity and
                    storage fumigation.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/fumigation/phosphine-fumigation"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Tobacco Fumigation</h3>
                <p>
                    Fumigation Services ensures top-quality tobacco fumigation,
                    adhering to international buyer standards, with audited
                    safety protocols and effective gas monitoring.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/tobacco-fumigation"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Ship Holds Fumigation</h3>
                <p>
                    Enhance timber durability with our quality products,
                    guarding against pests, fungi, and decay.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/ship-holds-fumigation"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Anti-fungal Treatment for Wood Packing Materials</h3>
                <p>
                    Ensure durable wooden packing materials with our effective
                    anti-fungal treatment for product showcasing abroad
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/anti-fungal-treatment"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Food Processing Units</h3>
                <p>
                    Fumigation Services ensures food processing machinery and
                    units remain pest-free, preserving food quality and
                    compliance standards.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="/service/food-processing-unit"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Household Pest Control</h3>
                <p>
                    Pest control gives protection against harmful insects that
                    cause health issues and even damages property.
                </p>

                <div class="theme-button">
                    <a
                        routerLink="service/household-pest-control"
                        class="default-btn"
                        >Read More</a
                    >
                </div>

                <div class="service-shape">
                    <img
                        src="assets/img/service/shapes/service-pattern-6.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-7.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-8.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-9.png"
                        alt="shape"
                    />
                    <img
                        src="assets/img/service/shapes/service-pattern-10.png"
                        alt="shape"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<section class="why-us">
    <div class="container-fluid">
        <div class="whyUsRowContainer ptb-100">
            <div class="col-lg-6 p-0">
                <img
                    class="why-us-img"
                    src="assets/img/home/whyUs.png"
                    alt="about image"
                />
            </div>

            <div class="col-lg-6">
                <div class="why-us-text">
                    <div class="section-head">
                        <span>Why Chooes Us</span>
                        <h2>Where Excellence</h2>
                        <h2 class="whyUsHeading">Meets Your Expectations!</h2>
                        <p>
                            Choose us for unmatched pest control expertise,
                            where your satisfaction, safety, and budget are our
                            top priorities.
                        </p>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Innovation Beyond Chemicals</h5>
                            <p>
                                We lead the way in pest control innovation,
                                offering a diverse range of solutions, from
                                herbal pesticides to cutting-edge rodent
                                repellents.
                            </p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Trusted Nationwide</h5>
                            <p>
                                Our relentless pursuit of excellence has earned
                                the trust of top clients across India, making us
                                their preferred choice for pest control
                                solutions.
                            </p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Expertise and Accreditation</h5>
                            <p>
                                Our company is driven by a dedicated team of
                                experts in entomology, chemistry, and
                                bio-sciences, ensuring the highest level of
                                knowledge and competence.
                            </p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Compliance and Training</h5>
                            <p class="mb-0">
                                With accredited technical managers and a
                                workforce of over 300 trained operators, we
                                adhere to stringent phytosanitary standards for
                                fumigation and pest control.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
