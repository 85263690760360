<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Household pest control and treatment</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Household pest control and treatment
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <img
                    class="service-img"
                    src="assets/img/service/pestcontrol.jpg"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Household pest control and treatment</h3>
                    <p>
                        Pests can cause severe health issues if not checked at
                        the right time. Pest control gives protection against
                        harmful insects that cause health issues and even
                        damages property.
                    </p>
                    <h6>Mosquito</h6>
                    <br />
                    <h6>Fly</h6>
                    <br />
                    <h6>Integrated Lizard</h6>
                    <br />
                    <h6>Spider</h6>
                    <br />
                    <h6>Anti Termite Treatment</h6>
                    <br />
                    <h6>Pro Guard Service</h6>
                    <br />
                    <h6>Fogging</h6>
                    <br />
                    <h6>Cockroach Treatment</h6>
                    <br />

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
