<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Gel Treatment</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Gel Treatment
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">

                <img
                    class="service-img"
                    src="assets/img/service/gel.png"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Gel Treatment</h3>
                    <p>
                        Highly effective, eco-friendly cockroach control with
                        long-lasting results, odorless and safe for sensitive
                        areas.
                    </p>
                    <div>
                        <h6>Safe and Environmentally Friendly:</h6>
                        <p>
                            Our gel application service is highly effective
                            without posing any harm to humans or the
                            environment. We prioritize safety in pest control.
                        </p>
                    </div>
                    <div>
                        <h6>Long-Lasting Results:</h6>
                        <p>
                            Our cockroach gel service offers enduring results.
                            You can trust that your home or kitchen will remain
                            cockroach-free for an extended period.
                        </p>
                    </div>
                    <div>
                        <h6>Odorless and Convenient:</h6>
                        <p>
                            Unlike traditional insecticide sprays with strong
                            odors, our gel treatment is odorless. There's no
                            need to vacate your home or disrupt your kitchen
                            routine.
                        </p>
                    </div>
                    <div>
                        <h6>Precise Application:</h6>
                        <p>
                            The gel can be applied with precision even in
                            sensitive areas like electrical boxes and
                            refrigerators. Cockroaches feed on the gel, setting
                            off a cascading effect that gradually reduces their
                            populations.
                        </p>
                    </div>
                    <div>
                        <h6>Multi-Purpose Effectiveness::</h6>
                        <p>
                            Beyond cockroaches, our gel treatment is effective
                            in controlling ants and silverfish, offering
                            comprehensive pest management for your peace of
                            mind.
                        </p>
                    </div>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
