import { Component, OnInit } from "@angular/core";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
    // ngOnInit() {
    public sendEmail(e: Event) {
        e.preventDefault();
        emailjs
            .sendForm(
                "aarav_email_service",
                "aarav_email_template",
                e.target as HTMLFormElement,
                "XmMw-Qk4PTAOjU2Cd"
            )
            .then(
                (result: EmailJSResponseStatus) => {
                    alert(
                        "Thank You, We have received your message, our team will contact you shortly."
                    );
                     // Reset the form by clearing the form fields
                const form = e.target as HTMLFormElement;
                form.reset();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    }
}
