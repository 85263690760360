import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-service-details",
    templateUrl: "./pest-control.component.html",
    styleUrls: ["./pest-control.component.scss"],
})
export class PestControlComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
