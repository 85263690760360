import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-service-details",
    templateUrl: "./methyl-fumigation.component.html",
    styleUrls: ["./methyl-fumigation.component.scss"],
})
export class MethylFumigationComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
