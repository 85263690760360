<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img
                            src="assets/img/contact-img.png"
                            alt="contact image"
                        />
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm" (submit)="sendEmail($event)">
                                <div class="row">
                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input
                                                type="text"
                                                name="from_name"
                                                id="name"
                                                class="form-control"
                                                placeholder="Your Name"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-6">
                                        <div class="form-group mb-3">
                                            <input
                                                type="email"
                                                name="reply_to"
                                                id="email"
                                                class="form-control"
                                                placeholder="Your Email"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea
                                                name="message"
                                                class="form-control"
                                                id="message"
                                                cols="30"
                                                rows="5"
                                                placeholder="Your Message"
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button
                                            type="submit"
                                            value="Send"
                                            class="default-btn page-btn"
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo" />
                        </a>
                    </div>

                    <ul class="find-us">
                        <li class="d-flex flex-row">
                            <i class="icofont-location-pin"></i>
                            <div>
                                Aarav Pest Management (IMT Manesar)/ Eros
                                Corporate park /Unit No-1101 Sec-02 IMT Manesar
                                Gurgaon Haryana 122051
                            </div>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="icofont-location-pin"></i>
                            <div>
                                Aarav Pest Management (Rewari) Gali No -10,
                                H.No-86 Shakti Nagar, Near Gokuldham Society
                                Rewari Haryana-123401
                            </div>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="icofont-location-pin"></i>
                            <div>
                                Aarav Pest Management (Delhi) Suite-1,III Floor
                                Plot No.15&16 Supreme Plaza, Sector-6,Central
                                Market Dwarka New Delhi-110075,INDIA
                            </div>
                        </li>
                        <li class="d-flex flex-row">
                            <i class="icofont-location-pin"></i>
                            <div>
                                Aarav Pest Management (Kachch), Plot No.45,
                                Office No.107, 1st Floor, Sector-9, Meritine
                                House Building, Nagarpalika Road, Opp. Patel
                                Chamber, C/o Gopi Logistics, Gandhidham, Kachch,
                                Pin-370201
                            </div>
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+919728882010">+91-9728882010</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:info@kiaz.com">
                                aaravpestmanagement@gmail.com</a
                            >
                        </li>
                    </ul>
                    <!-- <p>Lorem ipsum dolor sit amet, tur adipiscing elit, sed do eiusmod tempor contratc.</p>

                    <div class="email">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL">
                            <button class="default-btn electronics-btn" type="submit"><i class="icofont-location-arrow"></i></button>
                        </form>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Services</h3>

                    <ul>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/spray-treatment"
                                >Spray Treatment</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/gel-treatment"
                                >Gel Treatment</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/fumigation">Fumigation</a>
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a
                                routerLink="/service/fumigation/methyl-bromide-fumigation"
                                >Methyl Fumigation</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a
                                routerLink="/service/fumigation/phosphine-fumigation"
                                >Phosphine Fumigation</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/tobacco-fumigation"
                                >Tobacco Fumigation</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/ship-holds-fumigation"
                                >Ship Holds Fumigation</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/anti-fungal-treatment"
                                >Anti-fungal Treatment</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/service/food-processing-unit"
                                >Food Processing Units</a
                            >
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="service/household-pest-control"
                                >Household Pest Control</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Quick Links</h3>

                    <ul>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/about">About Us</a>
                        </li>
                        <li>
                            <i class="icofont-simple-right"></i>
                            <a routerLink="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="icofont-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="icofont-twitter"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="icofont-linkedin"></i
                                ></a>
                            </li>
                            <li>
                                <a href="#" target="_blank"
                                    ><i class="icofont-instagram"></i
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-end">
                        <p>copyrights @2024 | All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>
