<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Spray Treatment</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Spray Treatment
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">

                <img
                    class="service-img"
                    src="assets/img/service/spray.png"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Spray Treatment</h3>
                    <p>
                        Precise control through visual inspection and targeted
                        residual spraying.
                    </p>
                    <div>
                        <h6>Visual Inspection for Precision:</h6>
                        <p>
                            Our approach begins with a thorough visual
                            inspection of the infested premises. This meticulous
                            examination allows us to assess the extent of the
                            cockroach infestation accurately.
                        </p>
                    </div>
                    <div>
                        <h6>Targeted Population Management:</h6>
                        <p>
                            Armed with insights from our inspection, we
                            implement a strategic population management plan.
                            Residual spraying of contact insecticides is a key
                            component of this plan, meticulously applied to the
                            surfaces where cockroaches are prevalent.
                        </p>
                    </div>
                    <div>
                        <h6>Pinpoint Precision:</h6>
                        <p>
                            We take pride in our precision. Our trained
                            professionals ensure that the spray treatment
                            precisely targets cockroach hotspots, guaranteeing
                            maximum impact while minimizing environmental
                            exposure.
                        </p>
                    </div>
                    <div>
                        <h6>Lasting Results:</h6>
                        <p>
                            With our spray treatment, you can expect lasting
                            results. We go the extra mile to not only eliminate
                            existing cockroach populations but also to create a
                            barrier that deters future infestations, providing
                            you with peace of mind.
                        </p>
                    </div>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
