<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Phosphine Fumigation</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Phosphine Fumigation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">

                <img
                    class="service-img"
                    src="assets/img/service/IMG_3197.jpg"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Phosphine Fumigation</h3>
                    <p>
                        Aluminum Phosphide: Globally trusted for commodity and
                        storage fumigation.
                    </p>
                    <h6>Versatile Fumigation Solution:</h6>
                    <p>
                        Aluminum Phosphide, available in tablet and pouch forms,
                        is a versatile fumigant trusted globally. It serves as a
                        powerful tool for preserving both raw and processed
                        commodities, such as grains, seeds, tobacco, and more.
                    </p>
                    <h6>Preserving Quality Worldwide:</h6>
                    <p>
                        From the finest cocoa beans to essential animal feeds,
                        tea, coffee leaves, wheat flour, processed spices, and
                        dried fruits, Aluminum Phosphide ensures the
                        preservation of product quality on a global scale.
                    </p>
                    <h6>Beyond Commodities:</h6>
                    <p>
                        This effective fumigant extends its reach to
                        safeguarding storage structures as well. Whether it's
                        silos, warehouses, flour mills, ship holds, or railcars,
                        Aluminum Phosphide offers a comprehensive solution for
                        maintaining the integrity of your stored goods.
                    </p>
                    <h6>A Trusted Global Choice:</h6>
                    <p>
                        With a wide range of applications and a reputation for
                        reliability, Aluminum Phosphide stands as a trusted
                        choice in the world of fumigation, safeguarding valuable
                        commodities and storage facilities.
                    </p>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
