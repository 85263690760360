<div class="contact-title contact-title-bg">
    <img class="contact-title-img" src="assets/img/service/bg.png" alt="" />
    <div class="d-table contact-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="contact-title-text">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Contact Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-100"></div>