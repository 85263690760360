<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Tobacco Fumigation</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Tobacco Fumigation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <img
                    class="service-img"
                    src="assets/img/service/tobacco.jpg"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Tobacco Fumigation</h3>
                    <p>
                        Fumigation Services ensures top-quality tobacco
                        fumigation, adhering to international buyer standards,
                        with audited safety protocols and effective gas
                        monitoring.
                    </p>
                    <h6>Meeting International Buyer Demands:</h6>
                    <p>
                        Fumigation services are dedicated to meeting
                        international buyer's precise requirements for tobacco
                        fumigation. Whether it's in warehouses or containers, we
                        ensure your tobacco products comply with global
                        standards.
                    </p>
                    <h6>Rigorous Safety Audits:</h6>
                    <p>
                        Our commitment to safety is unwavering. Reputed tobacco
                        buyers from around the world regularly audit our
                        fumigation safety procedures, ensuring compliance with
                        the highest industry standards.
                    </p>
                    <h6>Phosphine Gas Expertise:</h6>
                    <p>
                        With advanced phosphine gas monitoring techniques and
                        effective sealing procedures, Fumigation services
                        consistently achieve outstanding results, guaranteeing
                        the preservation of your valuable tobacco products.
                    </p>
                    <h6>Your Assurance of Quality:</h6>
                    <p>
                        Choose Fumigation services as your partner in tobacco
                        fumigation, and rest assured that your tobacco goods are
                        handled with precision, safety, and expertise, meeting
                        the stringent demands of the global market.
                    </p>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
