import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-service-details",
    templateUrl: "./spray-treatment.component.html",
    styleUrls: ["./spray-treatment.component.scss"],
})
export class SprayTreatmentComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
