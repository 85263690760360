<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Lashing And Packing</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Lashing And Packing
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="service-details-post">
                    <div class="service-detail-card-div">
                        <div class="w-50">
                            <h2>Lashing And Packing</h2>
                            <br />
                            <h3>Cargo Inspection:</h3>
                            <p>
                                Ensuring that cargo is properly secured and
                                meets safety regulations.
                            </p>
                            <div>
                                <h3>Customized Lashing Solutions:</h3>
                                <p>
                                    Tailoring lashing methods and products to
                                    specific cargo and transport requirements.
                                </p>
                            </div>
                            <div>
                                <h3>Lashing Training:</h3>
                                <p>
                                    Providing training and certification
                                    programs for personnel involved in cargo
                                    securing.
                                </p>
                            </div>
                            <div>
                                <h3>Maintenance and Repairs:</h3>
                                <p>
                                    Inspection and maintenance of lashing
                                    equipment to ensure it remains safe and
                                    effective.
                                </p>
                            </div>
                            <div>
                                <h3>Consulting:</h3>
                                <p>
                                    Advising clients on cargo securing best
                                    practices and regulatory compliance
                                </p>
                            </div>
                            <div>
                                <h3>
                                    Compliance with International Standards:
                                </h3>
                                <p>
                                    Companies involved in lashing and cargo
                                    securing must adhere to international
                                    standards, such as guidelines from the
                                    International Maritime Organization (IMO)
                                    and national regulations to ensure the
                                    safety of cargo transport.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-img w-50"
                            src="assets/img/service/lashing and cargo stabilization.jpg"
                            alt="service"
                        />
                    </div>
                </div>
                <div class="service-details-post">
                    <h3>Our Lashing Services:-</h3>
                    <br />
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Cargo Securing:</h3>
                                <p>
                                    Ensuring that cargo is safely secured to
                                    prevent shifting or damage during
                                    transportation.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/cargosecuring.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Risk Assessment:</h3>
                                <p>
                                    Assessing the nature of the cargo, transport
                                    conditions, and potential risks to determine
                                    the appropriate lashing methods.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/safety.jpg"
                            alt="service"
                        />
                    </div>

                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>Airbag container lashing:</h3>
                                <p>
                                    Airbag container lashing, also known as
                                    dunnage bag container lashing or container
                                    void filling, is a cargo securing and
                                    protection technique used in shipping and
                                    logistics to prevent the movement and damage
                                    of cargo within shipping containers during
                                    transportation. These airbags are designed
                                    to fill the voids or gaps between cargo
                                    items and the walls of the container,
                                    thereby immobilizing the cargo and
                                    preventing it from shifting during transit.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/Airbag container.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>
                                    Key aspects of lashing inside a container
                                    include:
                                </h3>
                                <div>
                                    <h3>1. Cargo Stabilization:</h3>
                                    <p>
                                        Lashing techniques and equipment are
                                        used to stabilize cargo within the
                                        container, preventing it from shifting
                                        or falling over during transit. This is
                                        crucial for the safety of both the cargo
                                        and the container itself.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/lashing and cargo stabilization.jpg"
                            alt="service"
                        />
                    </div>

                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <div>
                                <h3>2. Types of Lashing Techniques:</h3>
                                <p>
                                    Blocking and Bracing: Wooden or metal blocks
                                    and braces are placed between cargo items or
                                    between cargo and container walls to secure
                                    them in place.
                                </p>
                                <p>
                                    Strapping and Banding: Strong straps or
                                    bands made of materials like polyester or
                                    steel are used to secure cargo to anchor
                                    points within the container.
                                </p>
                                <p>
                                    Dunnage Bags: Inflatable bags are placed
                                    between cargo items to fill voids and create
                                    a tight fit, minimizing movement.
                                </p>
                                <p>
                                    Twist Locks and Container Sockets: In some
                                    cases, specialized twist locks and sockets
                                    are used to secure cargo containers to the
                                    container's internal structure.
                                </p>
                                <p>
                                    Lashing on flat rack containers refers to
                                    the process of securing and fastening cargo
                                    on flat rack containers to prevent it from
                                    shifting, moving, or becoming damaged during
                                    transportation. Flat rack containers, also
                                    known as flatbed containers, are open
                                    containers with collapsible sides that
                                    provide flexibility for loading oversized or
                                    irregularly shaped cargo. Lashing on flat
                                    track containers is essential to ensure the
                                    safety of the cargo, the container, and the
                                    transportation process.
                                </p>
                            </div>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/flat rock container.jpg"
                            alt="service"
                        />
                    </div>
                </div>
                <div class="service-details-post">
                    <h4>Lashing Products:</h4>
                    <br />
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Lashing Straps:</h3>
                            <p>
                                Straps made of durable materials like polyester
                                or nylon, equipped with buckles or hooks for
                                securing cargo.
                            </p>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/code strap.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Chains and Binders:</h3>

                            <p>
                                Heavy-duty chains and binders for securing heavy
                                or irregularly shaped cargo.
                            </p>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/lashing and cargo stabilization.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Dunnage Bags:</h3>

                            <p>
                                Inflatable bags placed between cargo to fill
                                voids and prevent movement.
                            </p>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/dunnage-bag.png"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Lashing Bars and Beams:</h3>

                            <p>
                                Structural elements used to brace and secure
                                cargo in place.
                            </p>
                        </div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/bars-beams.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Corner Protectors:</h3>

                            <p>
                                Protecting cargo and straps from damage at
                                contact points.
                            </p>
                        </div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/corner.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Load Binders:</h3>

                            <p>
                                Devices used to tighten and secure chains around
                                cargo.
                            </p>
                        </div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/loadBinders.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Cargo Nets:</h3>
                            <p>
                                Nets designed to cover and secure irregularly
                                shaped cargo.
                            </p>
                        </div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/cargonets.jpeg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Composite Lashing</h3>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/compositeLashing.webp"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Woven Lashing</h3></div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/woven-lashing.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Buckles</h3></div>

                        <img
                            class="service-type-img"
                            src="assets/img/service/buckle.webp"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Lashing Tools</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/container-lashing-tool.webp"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Cargo Securing Accessories</h3>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/securingAccessories.jpg"
                            alt="service"
                        />
                    </div>
                    <br />
                    <h5>
                        We also Manufacture Wooden pallets and we trade in:-
                    </h5>

                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Cord strap Belt</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/code strap.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>VCI bags and Pouches</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/vcibags.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Stretch film</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/stretchfilm.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75">
                            <h3>Strap tools and accessories</h3>
                        </div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/strappingtool.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>BOPP tape</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/bopptape.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Pet strap rolls</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/pet-strap-roll.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="service-detail-card-div">
                        <div class="w-75"><h3>Container Desiccants</h3></div>
                        <img
                            class="service-type-img"
                            src="assets/img/service/container-desiccant.jpg"
                            alt="service"
                        />
                    </div>
                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
