import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ServiceTwoComponent } from "./components/pages/service-two/service-two.component";
import { SprayTreatmentComponent } from "./components/pages/spray-treatment/spray-treatment.component";
import { GelTreatmentComponent } from "./components/pages/gel-treatment/gel-treatment.component";
import { FumigationComponent } from "./components/pages/fumigation/fumigation.component";
import { MethylFumigationComponent } from "./components/pages/methyl-fumigation/methyl-fumigation.component";
import { PhosphineFumigationComponent } from "./components/pages/phosphine-fumigation/phosphine-fumigation.component";
import { TobaccoFumigationComponent } from "./components/pages/tobacco-fumigation/tobacco-fumigation.component";
import { ShipFumigationComponent } from "./components/pages/ship-fumigation/ship-fumigation.component";
import { AntiFungalComponent } from "./components/pages/anti-fungal/anti-fungal.component";
import { FoodProcessingComponent } from "./components/pages/food-processing/food-processing.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { LashingComponent } from "./components/pages/lashing/lashing.component";
import { PestControlComponent } from "./components/pages/pest-control/pest-control.component";

const routes: Routes = [
    { path: "", component: HomeThreeComponent },
    { path: "about", component: AboutComponent },
    { path: "service", component: ServiceTwoComponent },
    { path: "service/spray-treatment", component: SprayTreatmentComponent },
    { path: "service/gel-treatment", component: GelTreatmentComponent },
    { path: "service/fumigation", component: FumigationComponent },
    { path: "service/lashing-packing", component: LashingComponent },
    {
        path: "service/fumigation/methyl-bromide-fumigation",
        component: MethylFumigationComponent,
    },
    {
        path: "service/fumigation/phosphine-fumigation",
        component: PhosphineFumigationComponent,
    },
    {
        path: "service/tobacco-fumigation",
        component: TobaccoFumigationComponent,
    },
    {
        path: "service/ship-holds-fumigation",
        component: ShipFumigationComponent,
    },
    { path: "service/anti-fungal-treatment", component: AntiFungalComponent },
    {
        path: "service/food-processing-unit",
        component: FoodProcessingComponent,
    },
    {
        path: "service/household-pest-control",
        component: PestControlComponent,
    },

    { path: "error", component: ErrorComponent },
    { path: "contact", component: ContactComponent },
    // Here add new pages component

    { path: "**", component: ErrorComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
