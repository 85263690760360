<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Food Processing Units</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Food Processing Units
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <img
                    class="service-img"
                    src="assets/img/service/food.png"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Food Processing Units</h3>
                    <p>
                        Fumigation Services ensures food processing machinery
                        and units remain pest-free, preserving food quality and
                        compliance standards.
                    </p>
                    <h6>Fumigation of food processing machinery:</h6>
                    <p>
                        Fumigation Services operations staffs are well trained
                        and experienced in carrying out fumigation of flour mill
                        machinery, spices processing machinery and other food
                        processing machineries. Carrying out fumigation of food
                        processing machineries on a regular and periodic basis
                        would reduce the infestation level inside the machinery
                        ducts and chambers. In the longer run, this regular
                        exercise would control further infestation.
                    </p>
                    <p>
                        If regular and periodic fumigation is not carried out,
                        foods that are processed through these pipe lines can be
                        contaminated by various types of microorganisms. Most of
                        our customers undertake regular fumigation of food
                        processing machineries for maintaining quality of food
                        and avoid further infestation with us.
                    </p>
                    <h6>Pest control for food processing units:</h6>
                    <p>
                        Fumigation Services undertakes efficient pest
                        controlling measures to maintain pest free environment
                        in food processing industry. Fumigation Services staffs
                        regularly undertake insect monitoring process, spraying
                        operations and are well versed in maintaining the
                        records regarding pest control program conducted,
                        meeting compliance standards of the respective
                        regulatory bodies and meeting the requirements of
                        customers in records management.
                    </p>
                    <p>
                        Fumigation Services operators are trained and
                        experienced in conducting rodent control operations.
                        This is done by placing baits and glue boards.
                    </p>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
