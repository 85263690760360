<div class="error-title">
    <div class="d-table">
        <div class="d-table-cell">
            <h2><span>Oops!</span> Page not found</h2>
        </div>
    </div>
</div>

<div class="error-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="error-img">
                    <img src="assets/img/404.png" alt="error image">
                </div>
            </div>
        </div>

        <div class="theme-button text-center">
            <a routerLink="/" class="default-btn">go to home page</a>
        </div>
    </div>
</div>