<div class="service-title service-title-bg bg-3">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />

    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Ship Holds Fumigation</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Ship Holds Fumigation
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="service-details-area main-service-area pt-100 services-details-page"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">

                <img
                    class="service-img"
                    src="assets/img/service/ship.png"
                    alt="service"
                />
                <div class="service-details-post">
                    <h3>Ship Holds Fumigation</h3>
                    <p>
                        Enhance timber durability with our quality products,
                        guarding against pests, fungi, and decay.
                    </p>
                    <h6>Timber Protection for Durability:</h6>
                    <p>
                        Timber, a valuable natural resource, serves a multitude
                        of purposes, from home construction to furniture and
                        artistry. However, it's susceptible to insect damage,
                        dampness, and fungi, necessitating reliable protection.
                        We offer a comprehensive range of high-quality products
                        designed to safeguard timber in homes and gardens from
                        these potential issues.
                    </p>
                    <h6>Industrial Wood Preservative Treatment:</h6>
                    <p>
                        Timber destined for external use is often pre-treated
                        with industrial wood preservatives. Whether applied
                        through vacuum pressure treatment or dipping processes,
                        these preservatives enhance timber's natural durability
                        against fungal and insect threats. Treated timbers find
                        extensive applications in construction, including garden
                        and landscaping projects, playing structures, posts,
                        fences, decking, and garden furniture.
                    </p>
                    <h6>Permadip 9 for Outdoor Timber:</h6>
                    <p>
                        Our Permadip 9 is an industrial wood treatment
                        specifically crafted for rough-cut outdoor timber. This
                        treatment not only adds color but also imparts
                        preservative properties to the timber through the
                        dipping process. The active ingredients in Permadip 9
                        provide protection against fungi, mold, and wood-boring
                        insects.
                    </p>
                    <h6>Addressing Wood Decay and Insects:</h6>
                    <p>
                        Wood rotting fungi often coincide with wood-boring
                        insects, with Death Watch Beetle being a common culprit
                        linked to active decay. While oak is particularly prone
                        to infestations due to its extensive use in
                        construction, other timber types can also fall victim to
                        these damaging pests.
                    </p>

                    <div class="theme-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=09728882010"
                            class="default-btn"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
