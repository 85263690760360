<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li>
                            <i class="icofont-clock-time"></i> Mon - Sat :
                            10:00AM - 06:00PM
                        </li>
                        <li><i class="icofont-location-pin"> </i> Gurgaon</li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+919728882010">+91-9728882010</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li>
                            <a target="_blank" href="#"
                                ><i class="icofont-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a target="_blank" href="#"
                                ><i class="icofont-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a target="_blank" href="#"
                                ><i class="icofont-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a target="_blank" href="#"
                                ><i class="icofont-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo" />
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img class="logoImg" src="assets/img/logo.png" alt="logo" />
                </a>

                <div
                    class="collapse navbar-collapse mean-menu"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/about"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/service"
                                class="nav-link dropdown-toggle"
                                >Services <i class="icofont-rounded-right"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/fumigation"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Container Fumigation</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/lashing-packing"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Container Lashing And Packing</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/spray-treatment"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Spray Treatment</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/gel-treatment"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Gel Treatment</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/fumigation/methyl-bromide-fumigation"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Methyl Bromide Fumigation</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/fumigation/phosphine-fumigation"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Phosphine Fumigation</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/tobacco-fumigation"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Tobacco Fumigation</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/ship-holds-fumigation"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Ship Holds Fumigation</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/anti-fungal-treatment"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Anti-fungal Treatment</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/service/food-processing-unit"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Food Processing Units</a
                                    >
                                </li>   <li class="nav-item">
                                    <a
                                        routerLink="service/household-pest-control"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Household Pest Control</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Contact</a
                            >
                        </li>
                    </ul>

                    <div class="navbar-button">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://api.whatsapp.com/send?phone=919728882010"
                            >Get Quote</a
                        >
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
