<div class="service-title service-title-bg bg-1">
    <img class="service-title-img" src="assets/img/service/bg.png" alt="" />
    <div class="d-table service-title-text-container">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Services
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Viscous Provided <span>Services</span> We Offer</h2>
            <p>
                We provide a wide range of services with 100% customer
                satisfaction. House Hold Pest Management for flying & crawling
                insects.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-award"></i>
                    <h3>Spray Treatment</h3>
                    <p>
                        Precise control through visual inspection and targeted
                        residual spraying.
                    </p>

                    <div class="theme-button">
                        <a
                            routerLink="/service/spray-treatment"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-credit-card"></i>
                    <h3>Gel Treatment</h3>
                    <p>
                        Highly effective, eco-friendly cockroach control with
                        long-lasting results, odorless and safe for sensitive
                        areas.
                    </p>
                    <div class="theme-button">
                        <a
                            routerLink="/service/gel-treatment"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-clock"></i>
                    <h3>Fumigation</h3>
                    <p>
                        Fumigation is the use of lethal gasses to eliminate
                        pests, crucial for safeguarding international cargo
                    </p>

                    <div class="theme-button">
                        <a routerLink="/service/fumigation" class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-clock"></i>
                    <h3>Lashing and Packing</h3>
                    <p>
                        Ensuring that cargo is properly secured and meets safety
                        regulations
                    </p>

                    <div class="theme-button">
                        <a routerLink="/service/lashing-packing" class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-cleaning-spray"></i>
                    <h3>Methyl Bromide Fumigation</h3>
                    <p>
                        Essential for pest control in export cargo, but requires
                        post-fumigation care.
                    </p>
                    <div class="theme-button">
                        <a
                            routerLink="/service/fumigation/methyl-bromide-fumigation"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-clean-house"></i>
                    <h3>Phosphine Fumigation</h3>
                    <p>
                        Aluminum Phosphide: Globally trusted for commodity and
                        storage fumigation.
                    </p>
                    <div class="theme-button">
                        <a
                            routerLink="/service/fumigation/phosphine-fumigation"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Tobacco Fumigation</h3>
                    <p>
                        Fumigation Services ensures top-quality tobacco
                        fumigation, adhering to international buyer standards,
                        with audited safety protocols and effective gas
                        monitoring.
                    </p>
                    <div class="theme-button">
                        <a
                            routerLink="/service/tobacco-fumigation"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Ship Holds Fumigation</h3>
                    <p>
                        Enhance timber durability with our quality products,
                        guarding against pests, fungi, and decay.
                    </p>
                    <div class="theme-button">
                        <a
                            routerLink="/service/ship-holds-fumigation"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Anti-fungal Treatment for Wood Packing Materials</h3>
                    <p>
                        Ensure durable wooden packing materials with our
                        effective anti-fungal treatment for product showcasing
                        abroad
                    </p>

                    <div class="theme-button">
                        <a
                            routerLink="/service/anti-fungal-treatment"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Food Processing Units</h3>
                    <p>
                        Fumigation Services ensures food processing machinery
                        and units remain pest-free, preserving food quality and
                        compliance standards.
                    </p>

                    <div class="theme-button">
                        <a
                            routerLink="/service/food-processing-unit"
                            class="default-btn"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
